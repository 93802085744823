/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import {
  Box,
  Grid,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useState, type ReactElement } from "react";
import { MessageResponseTable } from "./MessageResponseTable";
import { LoadingButton } from "../../components/LoadingButton/LoadingButton";
import { useAtom, useAtomValue } from "jotai";
import {
  DeadLetterQueueFetchFields,
  loadableFetchDeadLetterQueueState,
  loadableRedriveDeadLetterQueueState,
  remainingDeadLetterQueueState,
} from "../../services/deadLetterQueueApi";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../../components/HookForms/ControlledTextField";
import { config } from "../../config/AdminUiConfig";
import { QueueTypeEnum } from "../../enums/QueueTypeEum";

/**
 * Dead Letter Queue view component
 * @returns Re-drive DLQ view component
 */
export function DeadLetterQueueView(): ReactElement {
  /** Currently stored deadLetterQueueItems in state */
  const deadLetterQueueItems = useAtomValue(remainingDeadLetterQueueState);
  /** Dispatch function to call fetch dead letter queue items API */
  const [fetchSetterState, fetch] = useAtom(loadableFetchDeadLetterQueueState);
  /** Dispatch function to call redrive dead letter queue items API */
  const [redriveSetterState, redrive] = useAtom(loadableRedriveDeadLetterQueueState);

  /** Open state for redrive dialog */
  const [dialogOpen, setDialogOpen] = useState(false);

  /** Create form to control fields */
  const { control, handleSubmit, getValues } = useForm<DeadLetterQueueFetchFields>({
    defaultValues: {
      region: config.activeRegions?.[0],
      queueType: QueueTypeEnum.IntegrationFilter,
      pollingDurationSeconds: 10,
    },
  });

  return (
    <Container maxWidth="md">
      <Grid height="100%" container direction="column">
        <Grid item xs={1}>
          <Typography variant="h4" gutterBottom>
            Queue Errors
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Grid container direction="row">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="start"
              component="form"
              noValidate
              autoComplete="off"
            >
              <Dialog
                open={dialogOpen}
                onClose={() => {
                  setDialogOpen(false);
                }}
                aria-labelledby="redrive-dialog-title"
                aria-describedby="redrive-dialog-description"
                id="redrive-dialog"
              >
                <DialogTitle id="redrive-dialog-title">Redrive queue</DialogTitle>
                <DialogContent>
                  <DialogContentText id="redrive-dialog-summary">
                    {`This will attempt to redrive the ${getValues("queueType")} queue in region: ${getValues(
                      "region",
                    )}.`}
                  </DialogContentText>
                  <br />
                  <DialogContentText>Are you sure you wish to redrive this queue?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogOpen(false);
                    }}
                    autoFocus
                  >
                    {"Do not redrive queue"}
                  </Button>
                  <Button
                    data-testid="redrive-dialog-submit"
                    type="submit"
                    onClick={handleSubmit((data) => {
                      /** Close dialog */
                      setDialogOpen(false);

                      /** Trigger redrive DLQ API call */
                      redrive({ region: data.region, queueType: data.queueType });
                    })}
                    color="error"
                    variant="contained"
                  >
                    Redrive
                  </Button>
                </DialogActions>
              </Dialog>
              <ControlledTextField
                id="region-select"
                select
                control={control}
                sx={{ m: 1 }}
                label="Region"
                name="region"
                rules={{
                  required: { value: true, message: "Required field" },
                }}
              >
                {config.activeRegions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </ControlledTextField>
              <ControlledTextField
                id="queue-type-select"
                select
                control={control}
                label="Queue Type"
                name="queueType"
                sx={{ m: 1 }}
                rules={{
                  required: { value: true, message: "Required field" },
                }}
              >
                {Object.values(QueueTypeEnum).map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </ControlledTextField>
              <ControlledTextField
                id="polling-duration"
                control={control}
                label="Polling Duration (s)"
                name="pollingDurationSeconds"
                type="number"
                sx={{ m: 1 }}
                rules={{
                  min: { value: 1, message: "Min: 1" },
                  valueAsNumber: true,
                  required: { value: true, message: "Required field" },
                }}
                InputProps={{
                  endAdornment: (
                    <LoadingButton
                      size="small"
                      sx={{ m: 0.5 }}
                      state={fetchSetterState.state}
                      variant="contained"
                      onClick={handleSubmit((data) => {
                        /** Trigger fetch DLQ items API call */
                        fetch(data);
                      })}
                    >
                      fetch
                    </LoadingButton>
                  ),
                }}
              />
              <LoadingButton
                sx={{ m: 1, mt: 2 }}
                state={redriveSetterState.state}
                variant="contained"
                onClick={handleSubmit(() => {
                  setDialogOpen(true);
                })}
              >
                Redrive Queue
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Card sx={{ mt: 4 }} component="section">
            {fetchSetterState.state === "hasError" ? (
              <CardContent>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                  <Typography>Error. Please try again.</Typography>
                </Box>
              </CardContent>
            ) : (
              <CardContent>
                <MessageResponseTable
                  loading={fetchSetterState.state === "loading"}
                  data={deadLetterQueueItems || []}
                />
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
