/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import {
  Box,
  Grid,
  MenuItem,
  Card,
  CardContent,
  Button,
  Tooltip,
  Tabs,
  Tab,
  CircularProgress,
  Collapse,
  IconButton,
  TextField,
} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useState, type ReactElement, useEffect } from "react";
import { LoadingButton } from "../../components/LoadingButton/LoadingButton";
import { useAtom, useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../../components/HookForms/ControlledTextField";
import { config } from "../../config/AdminUiConfig";
import { IngestAlertFields, ingestAlertFields, loadableIngestAlertState } from "../../services/ingestAlertApi";
import { ControlledDateTimePicker } from "../../components/HookForms/ControlledDateTimePicker";
import { ControlledJsonEditor } from "../../components/HookForms/ControlledJsonEditor";
import { Error, ExpandMore } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { MetadataInputMethod } from "../../services/MetadataInputMethod";
import { guidFormRules } from "../../helpers/validationRules";
import { AlertIngestRequestAlertMetadataForwardingSystemEnum } from "@sophos-socos/admin-api-client";

/**
 * Ingest Alert view component
 * @returns Ingest Alert view component
 */
export function IngestAlertView(): ReactElement {
  const [result] = useAtom(loadableIngestAlertState);
  const dispatch = useSetAtom(ingestAlertFields);

  /** Create form to control fields for request */
  const { control, handleSubmit, register, clearErrors } = useForm<IngestAlertFields>({
    defaultValues: {
      rawAlert: "",
      organisationId: "",
      region: config.activeRegions?.[0],
      alertMetadataJson: "",
    },
  });

  /* State for current date */
  const [currentDate, setCurrentDate] = useState(new Date());
  const [metadataTab, setMetadataTab] = useState<MetadataInputMethod>(MetadataInputMethod.FORM);
  const [expanded, setExpanded] = useState(true);

  /* Update current date value every second*/
  useEffect(() => {
    const timer = setInterval(() => setCurrentDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  /* Dispatch field updates to trigger ingest of alert */
  const handleIngestAlert = (data: IngestAlertFields) => {
    dispatch({ ...data, metadataInputMethod: metadataTab });
  };

  useEffect(() => {
    if (result.state === "hasData" && result.data !== undefined) {
      setExpanded(false);
    }
  }, [result]);

  return (
    <Container maxWidth="md">
      <Grid height="100%" container direction="column">
        <Grid item xs={1}>
          <Grid container direction="row">
            <Box display="flex" flexDirection="row" alignItems={"center"}>
              <Typography fontWeight={"bold"}>Current Timestamp</Typography>
              <Tooltip title="Copy epoch time">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(currentDate.getTime().toString());
                  }}
                >
                  <Typography sx={{ m: 1 }}> {currentDate.getTime()}</Typography>
                </Button>
              </Tooltip>
              <Tooltip title="Copy UTC time">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(currentDate.toUTCString());
                  }}
                >
                  <Typography sx={{ m: 1 }}>{currentDate.toUTCString()}</Typography>
                </Button>
              </Tooltip>
              <Tooltip title="Copy ISO time">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(currentDate.toISOString());
                  }}
                >
                  <Typography sx={{ m: 1 }}>{currentDate.toISOString()}</Typography>
                </Button>
              </Tooltip>
            </Box>
            <Card sx={{ width: "100%", mt: 4, pr: 2, pb: 1 }} component="section">
              <Box sx={{ m: 3, mb: 1 }} display={"flex"} alignItems={"center"} flexDirection={"row"} width={"100%"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant="h6">Ingest Alert</Typography>
                  <Typography fontStyle="italic" variant="subtitle2">
                    * indicates a required field
                  </Typography>
                </Box>
                <IconButton
                  sx={{ m: 1, transform: !expanded ? "rotate(0deg)" : "rotate(180deg)" }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <ExpandMore />
                </IconButton>
              </Box>
              <Collapse in={expanded}>
                <CardContent>
                  {/* Form grid */}
                  <Grid container direction={"column"}>
                    <Grid item sm={2}>
                      <ControlledTextField
                        fullWidth
                        id="raw-alert"
                        control={control}
                        sx={{ m: 1 }}
                        label="Raw Alert*"
                        name="rawAlert"
                        multiline
                        rules={{
                          required: { value: true, message: "Required" },
                          maxLength: { value: 5000, message: "Max length: 5000" },
                        }}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <ControlledTextField
                        id="organisation-id"
                        control={control}
                        sx={{ m: 1 }}
                        label="Organisation ID*"
                        name="organisationId"
                        rules={{
                          required: { value: true, message: "Required" },
                          ...guidFormRules,
                        }}
                      />
                      <ControlledTextField
                        id="agent-id"
                        control={control}
                        sx={{ m: 1 }}
                        label="Agent ID"
                        name="agentId"
                        rules={guidFormRules}
                      />
                      <ControlledTextField
                        id="alert-id"
                        control={control}
                        sx={{ m: 1 }}
                        label="Alert ID"
                        name="alertId"
                        rules={guidFormRules}
                      />
                      <ControlledTextField
                        id="region-select"
                        select
                        control={control}
                        sx={{ m: 1 }}
                        label="Region*"
                        name="region"
                      >
                        {config.activeRegions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </ControlledTextField>
                    </Grid>
                    <Grid item sm={2}>
                      <Box sx={{ m: 1, borderBottom: 1, borderColor: "divider" }} width={"100%"}>
                        <Typography variant="h6">Alert Metadata</Typography>
                        <Tabs
                          value={metadataTab}
                          onChange={(_, value) => setMetadataTab(value)}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Form" value={MetadataInputMethod.FORM} />
                          <Tab label="JSON" value={MetadataInputMethod.JSON} />
                        </Tabs>
                      </Box>
                    </Grid>
                    {metadataTab === MetadataInputMethod.FORM && (
                      <>
                        <Grid item sm={2}>
                          <ControlledDateTimePicker
                            control={control}
                            sx={{ m: 1 }}
                            label="Intial Ingestion Time"
                            name="alertMetadata.initialIngestionTimeMs"
                          />
                          <ControlledTextField
                            id="retry-count"
                            control={control}
                            sx={{ m: 1 }}
                            label="Retry Count"
                            name="alertMetadata.retryCount"
                            type="number"
                            rules={{
                              pattern: {
                                value: RegExp("^-?\\d+$"),
                                message: "Must be a whole integer",
                              },
                              min: { value: 0, message: "Min: 0" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <ControlledTextField
                            id="source-system"
                            control={control}
                            sx={{ m: 1 }}
                            label="Source System"
                            name="alertMetadata.sourceSystem"
                            rules={{
                              maxLength: { value: 100, message: "Max length: 100" },
                            }}
                          />
                          <ControlledTextField
                            id="source-system-alias"
                            control={control}
                            sx={{ m: 1 }}
                            label="Source System Alias"
                            name="alertMetadata.sourceSystemAlias"
                            rules={{
                              maxLength: { value: 50, message: "Max length: 50" },
                            }}
                          />
                          <ControlledTextField
                            id="source-system-version"
                            control={control}
                            sx={{ m: 1 }}
                            label="Source System Version"
                            name="alertMetadata.sourceSystemVersion"
                            rules={{
                              maxLength: { value: 50, message: "Max length: 50" },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <ControlledTextField
                            id="forwarding-system-select"
                            select
                            control={control}
                            sx={{ m: 1, minWidth: "25%" }}
                            label="Forwarding system"
                            name="alertMetadata.forwardingSystem"
                          >
                            {[
                              <MenuItem key={"none"} value={undefined}>
                                None
                              </MenuItem>,
                              ...Object.values(AlertIngestRequestAlertMetadataForwardingSystemEnum).map(
                                (option, index) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ),
                              ),
                            ]}
                          </ControlledTextField>
                        </Grid>
                      </>
                    )}
                    {metadataTab === MetadataInputMethod.JSON && (
                      <>
                        {/* The rich JSON editor is likely not ideal for screen reader usage and testing, so it's hidden and a text input is provided */}
                        <ControlledJsonEditor control={control} clearErrors={clearErrors} name="alertMetadataJson" />
                        {/* This is a simple text input for screen readers */}
                        <Box sx={visuallyHidden}>
                          <TextField {...register("alertMetadataJson")} label="Alert Metadata" />
                        </Box>
                      </>
                    )}
                  </Grid>
                </CardContent>
                <Box sx={{ m: 1, mt: 0 }} display="flex" flexDirection="row" justifyContent="flex-end">
                  <LoadingButton
                    state={result.state}
                    onClick={handleSubmit((data) => handleIngestAlert(data))}
                    variant="contained"
                  >
                    Ingest Alert
                  </LoadingButton>
                </Box>
              </Collapse>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Card sx={{ mt: 4 }} component="section">
            {result.state === "hasData" && result.data ? (
              <CardContent>
                <Grid container direction={"column"}>
                  <Typography variant="h6">Alert successfully ingested</Typography>
                  <Typography sx={{ m: 0.5 }}>{`Organisation ID: ${result.data.organisationId}`}</Typography>
                  <Typography sx={{ m: 0.5 }}>{`Alert ID: ${result.data.alertId}`}</Typography>
                  <Typography sx={{ m: 0.5 }}>{`Agent ID: ${result.data.agentId}`}</Typography>
                  <Typography sx={{ m: 0.5 }}>{`Object Key: ${result.data.objectKey}`}</Typography>
                </Grid>
              </CardContent>
            ) : result.state === "hasError" ? (
              <Box display="flex" justifyContent="center">
                <Box display="flex" alignItems="center">
                  <Error color="error" sx={{ m: 2 }} />
                  Failed to ingest alert
                </Box>
              </Box>
            ) : result.state === "loading" ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
