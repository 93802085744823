/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import { ThreatMappingsApi } from "@sophos-socos/admin-api-client";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { DEFAULT_OPTIONS } from "../helpers/defaultApiOptions";
import { config } from "../config/AdminUiConfig";
import { refreshAtom } from "../helpers/atoms";
import { handleError } from "./apiError";
import { accessTokenAtom } from "./userService";

/** The API */
const threatMappingsApi = new ThreatMappingsApi(DEFAULT_OPTIONS, config.apiUri);

/** The fetch threat mapping fields */
export type FetchThreatMappingFields = {
  pageSize: number;
  region?: string;
};

/** The request input fields */
export const fetchThreatMappingFields = atom<null | FetchThreatMappingFields>(null);

/** Refresher */
const [refreshGetter, refreshSetter] = refreshAtom();

/**
 * Selector for the fetch threat mapping API
 * @param [field, idToken]
 */
const fetchThreatMappingState = atom(async (get) => {
  const accessToken = get(accessTokenAtom);
  const fields = get(fetchThreatMappingFields);

  if (fields !== null) {
    // Make this refreshable
    get(refreshGetter);

    try {
      const { data } = await threatMappingsApi.fetchThreatMappings(fields.pageSize, fields.region, {
        headers: { Authorization: accessToken },
      });
      return data;
    } catch (error: unknown) {
      handleError(error as Error, refreshSetter);
      throw error;
    }
  }
});

/** Loadable atom */
export const loadableFetchThreatMappingState = loadable(fetchThreatMappingState);
